<template>
	<div class="cus-table">
		<CustomTableCustomer
			:total-pages="totalPages"
			:total-items="totalItems"
			:clinics="clinics"
			:show-export-button="true"
			@create="create"
			@search="search"
			@export="exportExcel"
		>
			<template #headers>
				<tr>
					<th
						v-for="(header, index1) in headers"
						:key="`${header.title}-${index1}`"
						scope="col"
						:class="`${header.class}`"
						>{{ header.title }}</th
					>
				</tr>
			</template>
			<template #body>
				<template v-if="customers && customers.length">
					<tr
						v-for="(customer, index2) in customers"
						:key="`${customer.id}-${index2}`"
						@click="goToUser(customer.id)"
						@click.right="goToUserNewTab(customer.id)"
						@click.middle="goToUserNewTab(customer.id)"
					>
						<td scope="row" class="text-break text-pre-wrap cus-table__name">
							<div>
								<span>{{ $t("CustomerForm.CustomerName") }}: </span>{{ customer.fullName }}
								<img v-if="customer.deviceCount" v-lazy="phoneIcon" alt class="phoneIcon" />
							</div>
							<div>
								<span>{{ $t("CustomerForm.CustomerSDT") }}: </span>{{ customer.phone }}</div
							>
							<div>
								<span>{{ $t("CustomerForm.Birthday") }}: </span>{{ customer.birthYear }}</div
							>
							<div v-if="isSystemAdmin && customer.nickname != null">
								<span>{{ $t("CustomerForm.Nickname") }}: </span>{{ customer.nickname }}</div
							>
						</td>
						<td scope="row" class="text-break text-pre-wrap">{{
							getCustomerType(customer.customerType)
						}}</td>
						<td scope="row" class="text-break text-pre-wrap">{{ customer.prepStartDate }}</td>
						<td scope="row" class="text-break text-pre-wrap">{{ customer.examinationCount }}</td>
						<td scope="row" :class="`fit ${getStatusClass(customer.status)}`">{{
							getStatus(customer.status)
						}}</td>
						<td scope="row" class="fit text-center actions">
							<router-link
								:to="{
									name: 'CustomerDetail',
									params: { id: customer.id },
								}"
								class="link-icon text-info"
							>
								<span class="prefix-input d-inline-block">
									<font-awesome-icon :icon="['fas', 'eye']" :title="$t('Action.Detail')" />
								</span>
							</router-link>
						</td>
					</tr>
				</template>
				<template v-else>
					<tr class="text-center">
						<td colspan="12" class="fit">
							{{ $t("Table.NoData") }}
						</td>
					</tr>
				</template>
			</template>
		</CustomTableCustomer>
	</div>
</template>

<script>
import {
	GET_CUSTOMER_LIST,
	GET_CLINIC_LIST,
	GET_REPORT_CUSTOMER_EXPORT,
} from "../store/action-types"
import { RESET_FILTERS_CUSTOMER_LIST } from "../store/mutation-types"
import { createNamespacedHelpers, mapState } from "vuex"
const { mapActions, mapMutations } = createNamespacedHelpers("customers")
import { STATUS, CUSTOMER_TYPE } from "@/shared/plugins/constants"
import phoneIcon from "@/assets/img/phone_icon.jpeg"
import autoIcon from "@/assets/img/auto_icon.png"
import { USER_ROLE } from "../../../shared/plugins"

export default {
	name: "CustomerManagement",

	data() {
		return {
			headers: [
				{
					title: this.$t("CustomerForm.Customer"),
					class: "text-nowrap cus-table__name",
				},
				{
					title: this.$t("CustomerForm.Type"),
					class: "text-nowrap name",
				},
				{
					title: this.$t("CustomerForm.Prep_date"),
					class: "text-nowrap name",
				},
				{
					title: this.$t("CustomerForm.Count"),
					class: "text-nowrap email",
				},
				{
					title: this.$t("CustomerForm.Status"),
					class: "text-nowrap",
				},
				{
					title: this.$t("CustomerManagement.Actions"),
					class: "fit text-center",
				},
			],

			listCustomersType: [
				{
					name: this.$t("CustomersType.PrEP"),
					value: CUSTOMER_TYPE.PREP,
				},
				{
					name: this.$t("CustomersType.Normal"),
					value: CUSTOMER_TYPE.NORMAL,
				},
				{
					name: this.$t("CustomersType.Unofficial"),
					value: CUSTOMER_TYPE.UNOFFICIAL,
				},
			],

			paramsExport: {},
			phoneIcon,
			autoIcon,
		}
	},

	computed: {
		...mapState("customers", ["customers", "clinics", "totalItems", "totalPages", "recentFilters"]),
		...mapState("authentication", ["currentUser"]),
		isSystemAdmin() {
			const role = this.isMobile ? this.mobileRole : this.currentUser?.role
			return role === USER_ROLE.SYSTEM_ADMIN
		},
	},

	created() {
		this.setData()
	},

	beforeDestroy() {
		if (!this.$route.path.includes("customers")) this.RESET_FILTERS_CUSTOMER_LIST()
	},

	methods: {
		...mapActions({
			GET_CLINIC_LIST,
			GET_CUSTOMER_LIST,
			GET_REPORT_CUSTOMER_EXPORT,
		}),
		...mapMutations({ RESET_FILTERS_CUSTOMER_LIST }),

		setData: async function () {
			await this.GET_CLINIC_LIST()
		},

		getStatus(status) {
			if (status === STATUS.ACTIVE) return this.$t("Status.Active")
			return this.$t("Status.Inactive")
		},

		getCustomerType(status) {
			return this.listCustomersType.filter(el => el.value === status)[0].name || ""
		},

		create() {
			this.$router.push({ name: "CreateCustomer" })
		},

		search: async function (params = {}) {
			this.paramsExport = { ...params }
			await this.GET_CUSTOMER_LIST(params)
		},

		goToUser(userId) {
			this.$router.push({ name: "CustomerDetail", params: { id: userId } })
		},

		goToUserNewTab(userId) {
			let routeData = this.$router.resolve({
				name: "CustomerDetail",
				params: { id: userId },
			})
			window.open(routeData.href, "_blank")
		},

		getStatusClass(status) {
			return {
				[STATUS.ACTIVE]: "status--active",
				[STATUS.INACTIVE]: "status--inactive",
			}[status]
		},

		exportExcel: async function () {
			await this.GET_REPORT_CUSTOMER_EXPORT(this.paramsExport)
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";

.cus-table {
	&__name {
		min-width: 150px;
		max-width: 250px;
	}

	&__name > div {
		// white-space: nowrap !important;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__name {
		> div {
			margin-bottom: 0.5rem;
		}
	}

	tbody tr:hover {
		cursor: pointer;
	}
}
.contact-method {
	width: 25px;
	height: 25px;
	margin-right: 7px;
}

.status {
	&--active {
		color: $color-sea-green;
	}
	&--inactive {
		color: $color-red;
	}
}

.phoneIcon {
	width: 100%;
	max-width: 23px;
	height: auto;
}
</style>
